<template>
    <div class="calendar-day">
        <div class="moving-image" :class="{ 'flip': isFlipped }">
            <img class="katze" :src="imageSrc" alt="Moving Image">
        </div>
        <div class="flying-image" :class="{ 'animate': isFlying }" @click="startFlying"></div>
        <div class="rainbow-trail" :class="{ 'animate': isFlying }"></div>
        <div class="day-content">
            <!-- 上半部分：万年历常规数据 -->
            <div class="calendar-info">
                <h2 class="lunar-date">{{ currentDay.solarYear }}年</h2>
                <h3 class="additional-info">{{ currentDay.solarMonth }}月{{ currentDay.solarDay }}日</h3>
                <p class="additional-info">阴历:{{ currentDay.lunarMonth }}{{ currentDay.lunarDay }}</p>
                <p class="additional-info">{{ currentDay.jieQi24 }}</p>
                <!-- 导航箭头 -->
                <div class="navigation-arrows">
                    <div class="arrow-container">
                        <van-icon name="arrow-left" v-if="currentIndex > 0" @click="navigateDay(-1)" />
                    </div>
                    <div class="arrow-container">
                        <van-icon name="arrow" v-if="currentIndex < monthData.length - 1" @click="navigateDay(1)" />
                    </div>
                </div>
            </div>
            <div class="smile-curve"></div>
            <!-- 下半部分：5段话 -->
            <div class="daily-quotes">
                <div v-for="(quote, index) in currentDay.durchSages" :key="index" class="quote-item">
                    <p class="quote-text">{{ quote }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { NavBar, Cell, CellGroup, Icon } from 'vant';
import katze from '../assets/katze.png'
export default {
    components: {
        [NavBar.name]: NavBar,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Icon.name]: Icon,
    },
    props: {
        monthData: {
            type: Array,
            required: true
        },
        initialId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isFlying: false,
            currentIndex: 0,
            imageSrc: katze,
            isFlipped: false
        };
    },
    computed: {
        currentDay() {
            return this.monthData[this.currentIndex];
        }
    },
    created() {
        this.currentIndex = parseInt(this.initialId)
    },
    methods: {
        startFlying() {
            if (this.isFlying) return;
            this.isFlying = true;
            setTimeout(() => {
                this.isFlying = false;
                this.$router.push({ name: 'CalendarPage', params: { backMonate: this.monthData } });
            }, 1500);
        },
        navigateDay(direction) {
            const newIndex = this.currentIndex + direction;
            if (newIndex >= 0 && newIndex < this.monthData.length) {
                this.currentIndex = newIndex;
            }
        },
        startAnimation() {
            setInterval(() => {
                this.isFlipped = !this.isFlipped;
            }, 2000);
        }
    },
    mounted() {
        this.startAnimation();
    },
};
</script>
<style scoped>
.calendar-day {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    min-height: 87vh;
    position: relative;
}

.day-content {
    padding: 20px;

}

.calendar-info {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.calendar-info::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 20px;
    background-color: #fff;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.lunar-date {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.additional-info {
    font-size: 16px;
    color: #666;
    text-align: center;
}

.navigation-arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    transform: translateY(-50%);
}

.arrow-container {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    /* 或者您希望的任何固定宽度 */
    height: 40px;
    /* 或者您希望的任何固定高度 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-arrows .van-icon {
    font-size: 40px;
    font-weight: 500;
    color: #faa719;
    cursor: pointer;
    opacity: 0.3;
}

.daily-quotes {
    background-color: #fff;
    padding: 20px;
    position: relative;
    margin-top: -20px;
    height: 40vh;
    /* 使两个元素重叠 */
    z-index: 1;
}

.daily-quotes::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 20px;
    background-color: #fff;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.quote-item {
    margin-bottom: 15px;
}

.quote-text {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
}


.smile-curve {
    height: 40px;
    background-color: #fff;
    position: relative;
    z-index: 3;
    overflow: hidden;
}

.smile-curve::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    height: 40px;
    background-color: #d87716;
    /* 背景色，可以根据需要调整 */
    border-radius: 50%;
}


.flying-image {
    position: absolute;
    top: 5vh;
    left: 20px;
    width: 50px;
    height: 50px;
    background-image: url('../assets/soxu2.png');
    background-size: cover;
    cursor: pointer;
    z-index: 10;
}

.flying-image.animate {
    animation: fly 2s ease-in-out forwards;
}

.rainbow-trail {
    position: absolute;
    top: 45px;
    left: 45px;
    width: 0;
    height: 100px;
    /* 增加彩虹的宽度 */
    background: linear-gradient(to bottom,
            rgba(255, 0, 0, 0.5),
            rgba(255, 165, 0, 0.5),
            rgba(255, 255, 0, 0.5),
            rgba(0, 255, 0, 0.5),
            rgba(0, 0, 255, 0.5),
            rgba(75, 0, 130, 0.5),
            rgba(238, 130, 238, 0.5));
    opacity: 0;
    transform-origin: left top;
    z-index: 5;
    border-radius: 50px;
    /* 圆角效果 */
}

.rainbow-trail.animate {
    animation: trail 2s ease-in-out forwards;
}

@keyframes fly {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(calc(100vw - 70px), calc(100vh - 70px));
        width: 200px;
        height: 300px;
    }
}

@keyframes trail {
    0% {
        width: 0;
        opacity: 0;
        transform: rotate(0deg) scaleY(0);
    }

    20% {
        opacity: 1;
        transform: rotate(0deg) scaleY(1);
    }

    100% {
        width: 141vw;
        /* 近似对角线长度 √(100² + 100²) */
        opacity: 0;
        transform: rotate(45deg) scaleY(1);
    }
}

@keyframes moveLeftRight {

    0%,
    100% {
        left: 0;
    }

    50% {
        left: calc(100% - 100px);
        /* 假设图片宽度为100px，根据实际情况调整 */
    }
}

.moving-image {
    position: absolute;
    animation: moveLeftRight 4s linear infinite;
    transition: transform 0.4s;
    z-index: 10;
    top: 204px;

}

.moving-image.flip {
    transform: scaleX(-1);
}

@keyframes moveLeftRight {

    0%,
    100% {
        left: 0;
    }

    50% {
        left: calc(100% - 100px);
        /* 假设图片宽度为100px，根据实际情况调整 */
    }
}

.katze {
    width: 100px;
    /* 根据需要调整图片大小 */
    height: auto;
}
</style>
