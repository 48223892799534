// sensitiveWords.js
export default [
  '共',
  '党',
  'gcd',
  'GCD',
  'GONG',
  'gong',
  'Gong',
  '中国',
  '中共',
  '打',
  '操',
  '反',
  '倒',
  'dadao',
  'dao',
  '法',
  '轮',
  '大法',
  '藏',
  '疆',
  '独',
  '台',
  'xi',
  'XI',
  '习',
  '国母',
  '彭',
  'peng',
  '习近',
  '彭丽',
  '近平',
  '小平',
  '毛',
  '泽东',
  // ... 更多敏感词
];
