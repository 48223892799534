import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import BaiduMap from 'vue-baidu-map'
import { Icon } from 'vant'

Vue.use(Icon)
Vue.use(BaiduMap, { ak: 'vgyeBVMIZ0tCEfUGRroq7wLwyqfjqNj9' })
Vue.use(Vant)
Vue.config.productionTip = false
window.vueInst=new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
