export const provinces = [
  { code: "11", name: "北京市" },
  { code: "12", name: "天津市" },
  { code: "13", name: "河北省" },
  { code: "14", name: "山西省" },
  { code: "15", name: "内蒙古自治区" },
  { code: "21", name: "辽宁省" },
  { code: "22", name: "吉林省" },
  { code: "23", name: "黑龙江省" },
  { code: "31", name: "上海市" },
  { code: "32", name: "江苏省" },
  { code: "33", name: "浙江省" },
  { code: "34", name: "安徽省" },
  { code: "35", name: "福建省" },
  { code: "36", name: "江西省" },
  { code: "37", name: "山东省" },
  { code: "41", name: "河南省" },
  { code: "42", name: "湖北省" },
  { code: "43", name: "湖南省" },
  { code: "44", name: "广东省" },
  { code: "45", name: "广西壮族自治区" },
  { code: "46", name: "海南省" },
  { code: "50", name: "重庆市" },
  { code: "51", name: "四川省" },
  { code: "52", name: "贵州省" },
  { code: "53", name: "云南省" },
  { code: "54", name: "西藏自治区" },
  { code: "61", name: "陕西省" },
  { code: "62", name: "甘肃省" },
  { code: "63", name: "青海省" },
  { code: "64", name: "宁夏回族自治区" },
  { code: "65", name: "新疆维吾尔自治区" },
  { code: "71", name: "台湾省" },
  { code: "81", name: "香港特别行政区" },
  { code: "82", name: "澳门特别行政区" },

];

export const cities = {
  "11": [
    { code: "1101", name: "东城区", lng: 116.4166, lat: 39.9288 },
    { code: "1102", name: "西城区", lng: 116.3666, lat: 39.9145 },
    { code: "1105", name: "朝阳区", lng: 116.4977, lat: 39.9284 },
    { code: "1106", name: "丰台区", lng: 116.2867, lat: 39.8585 },
    { code: "1107", name: "石景山区", lng: 116.2229, lat: 39.9056 },
    { code: "1108", name: "海淀区", lng: 116.2980, lat: 39.9592 },
    { code: "1109", name: "门头沟区", lng: 116.1017, lat: 39.9404 },
    { code: "1111", name: "房山区", lng: 116.1435, lat: 39.7479 },
    { code: "1112", name: "通州区", lng: 116.6566, lat: 39.9097 },
    { code: "1113", name: "顺义区", lng: 116.6546, lat: 40.1305 },
    { code: "1114", name: "昌平区", lng: 116.2313, lat: 40.2207 },
    { code: "1115", name: "大兴区", lng: 116.3416, lat: 39.7266 },
    { code: "1116", name: "怀柔区", lng: 116.6318, lat: 40.3160 },
    { code: "1117", name: "平谷区", lng: 117.1210, lat: 40.1414 },
    { code: "1118", name: "密云区", lng: 116.8429, lat: 40.3767 },
    { code: "1119", name: "延庆区", lng: 115.9748, lat: 40.4568 }
  ],
  "12": [
    { code: "1201", name: "和平区", lng: 117.2145, lat: 39.1171 },
    { code: "1202", name: "河东区", lng: 117.2514, lat: 39.1284 },
    { code: "1203", name: "河西区", lng: 117.2232, lat: 39.1090 },
    { code: "1204", name: "南开区", lng: 117.1508, lat: 39.1384 },
    { code: "1205", name: "河北区", lng: 117.1969, lat: 39.1480 },
    { code: "1206", name: "红桥区", lng: 117.1515, lat: 39.1671 },
    { code: "1207", name: "东丽区", lng: 117.3139, lat: 39.0863 },
    { code: "1208", name: "西青区", lng: 117.0093, lat: 39.1412 },
    { code: "1209", name: "津南区", lng: 117.3573, lat: 38.9987 },
    { code: "1210", name: "北辰区", lng: 117.1354, lat: 39.2241 },
    { code: "1211", name: "武清区", lng: 117.0445, lat: 39.3843 },
    { code: "1212", name: "宝坻区", lng: 117.3103, lat: 39.7176 },
    { code: "1213", name: "滨海新区", lng: 117.6968, lat: 39.0273 },
    { code: "1214", name: "宁河区", lng: 117.8258, lat: 39.3297 },
    { code: "1215", name: "静海区", lng: 116.9743, lat: 38.9476 },
    { code: "1216", name: "蓟州区", lng: 117.4084, lat: 40.0456 }
  ],
  "13": [
    { code: "1301", name: "石家庄市", lng: 114.502461, lat: 38.045474 },
    { code: "1302", name: "唐山市", lng: 118.175393, lat: 39.635113 },
    { code: "1303", name: "秦皇岛市", lng: 119.586579, lat: 39.942531 },
    { code: "1304", name: "邯郸市", lng: 114.490686, lat: 36.612273 },
    { code: "1305", name: "邢台市", lng: 114.508851, lat: 37.0682 },
    { code: "1306", name: "保定市", lng: 115.482331, lat: 38.867657 },
    { code: "1307", name: "张家口市", lng: 114.884091, lat: 40.811901 },
    { code: "1308", name: "承德市", lng: 117.939152, lat: 40.976204 },
    { code: "1309", name: "沧州市", lng: 116.857461, lat: 38.310582 },
    { code: "1310", name: "廊坊市", lng: 116.704441, lat: 39.523927 },
    { code: "1311", name: "衡水市", lng: 115.665993, lat: 37.735097 }
  ],
  "14": [
    { code: "1401", name: "太原市", lng: 112.549248, lat: 37.857014 },
    { code: "1402", name: "大同市", lng: 113.295259, lat: 40.09031 },
    { code: "1403", name: "阳泉市", lng: 113.583285, lat: 37.861188 },
    { code: "1404", name: "长治市", lng: 113.113556, lat: 36.191112 },
    { code: "1405", name: "晋城市", lng: 112.851274, lat: 35.497553 },
    { code: "1406", name: "朔州市", lng: 112.433387, lat: 39.331261 },
    { code: "1407", name: "晋中市", lng: 112.736465, lat: 37.696495 },
    { code: "1408", name: "运城市", lng: 111.003957, lat: 35.022778 },
    { code: "1409", name: "忻州市", lng: 112.733538, lat: 38.41769 },
    { code: "1410", name: "临汾市", lng: 111.517973, lat: 36.08415 },
    { code: "1411", name: "吕梁市", lng: 111.134335, lat: 37.524366 }
  ],
  "15": [
    { code: "1501", name: "呼和浩特市", lng: 111.670801, lat: 40.818311 },
    { code: "1502", name: "包头市", lng: 109.840405, lat: 40.658168 },
    { code: "1503", name: "乌海市", lng: 106.825563, lat: 39.673734 },
    { code: "1504", name: "赤峰市", lng: 118.956806, lat: 42.275317 },
    { code: "1505", name: "通辽市", lng: 122.263119, lat: 43.617429 },
    { code: "1506", name: "鄂尔多斯市", lng: 109.99029, lat: 39.817179 },
    { code: "1507", name: "呼伦贝尔市", lng: 119.758168, lat: 49.215333 },
    { code: "1508", name: "巴彦淖尔市", lng: 107.416959, lat: 40.757402 },
    { code: "1509", name: "乌兰察布市", lng: 113.114543, lat: 41.034126 },
    { code: "1522", name: "兴安盟", lng: 122.070317, lat: 46.076268 },
    { code: "1525", name: "锡林郭勒盟", lng: 116.090996, lat: 43.944018 },
    { code: "1529", name: "阿拉善盟", lng: 105.728969, lat: 38.851892 }
  ],
  "21": [
    { code: "2101", name: "沈阳市", lng: 123.429096, lat: 41.796767 },
    { code: "2102", name: "大连市", lng: 121.618622, lat: 38.91459 },
    { code: "2103", name: "鞍山市", lng: 122.995632, lat: 41.110626 },
    { code: "2104", name: "抚顺市", lng: 123.921109, lat: 41.875956 },
    { code: "2105", name: "本溪市", lng: 123.770519, lat: 41.297909 },
    { code: "2106", name: "丹东市", lng: 124.383044, lat: 40.124296 },
    { code: "2107", name: "锦州市", lng: 121.135742, lat: 41.119269 },
    { code: "2108", name: "营口市", lng: 122.235151, lat: 40.667432 },
    { code: "2109", name: "阜新市", lng: 121.648962, lat: 42.011796 },
    { code: "2110", name: "辽阳市", lng: 123.18152, lat: 41.269402 },
    { code: "2111", name: "盘锦市", lng: 122.06957, lat: 41.124484 },
    { code: "2112", name: "铁岭市", lng: 123.844279, lat: 42.290585 },
    { code: "2113", name: "朝阳市", lng: 120.451176, lat: 41.576758 },
    { code: "2114", name: "葫芦岛市", lng: 120.856394, lat: 40.755572 }
  ],
  "22": [
    { code: "2201", name: "长春市", lng: 125.3245, lat: 43.886841 },
    { code: "2202", name: "吉林市", lng: 126.55302, lat: 43.843577 },
    { code: "2203", name: "四平市", lng: 124.370785, lat: 43.170344 },
    { code: "2204", name: "辽源市", lng: 125.145349, lat: 42.902692 },
    { code: "2205", name: "通化市", lng: 125.936501, lat: 41.721177 },
    { code: "2206", name: "白山市", lng: 126.427839, lat: 41.942505 },
    { code: "2207", name: "松原市", lng: 124.823608, lat: 45.118243 },
    { code: "2208", name: "白城市", lng: 122.841114, lat: 45.619026 },
    { code: "2224", name: "延边朝鲜族自治州", lng: 129.513228, lat: 42.904823 }
  ],
  "23": [
    { code: "2301", name: "哈尔滨市", lng: 126.642464, lat: 45.756967 },
    { code: "2302", name: "齐齐哈尔市", lng: 123.918186, lat: 47.354348 },
    { code: "2303", name: "鸡西市", lng: 130.975966, lat: 45.300046 },
    { code: "2304", name: "鹤岗市", lng: 130.277487, lat: 47.332085 },
    { code: "2305", name: "双鸭山市", lng: 131.157304, lat: 46.643442 },
    { code: "2306", name: "大庆市", lng: 125.11272, lat: 46.590734 },
    { code: "2307", name: "伊春市", lng: 128.899396, lat: 47.724775 },
    { code: "2308", name: "佳木斯市", lng: 130.361634, lat: 46.809606 },
    { code: "2309", name: "七台河市", lng: 131.015584, lat: 45.771266 },
    { code: "2310", name: "牡丹江市", lng: 129.618602, lat: 44.582962 },
    { code: "2311", name: "黑河市", lng: 127.499023, lat: 50.249585 },
    { code: "2312", name: "绥化市", lng: 126.99293, lat: 46.637393 },
    { code: "2327", name: "大兴安岭地区", lng: 124.711526, lat: 52.335262 }
  ],
  "31": [
    { code: "3101", name: "黄浦区", lng: 121.4846, lat: 31.2293 },
    { code: "3103", name: "徐汇区", lng: 121.4367, lat: 31.1885 },
    { code: "3104", name: "长宁区", lng: 121.4242, lat: 31.2204 },
    { code: "3105", name: "静安区", lng: 121.4484, lat: 31.2286 },
    { code: "3106", name: "普陀区", lng: 121.3971, lat: 31.2495 },
    { code: "3107", name: "虹口区", lng: 121.5054, lat: 31.2646 },
    { code: "3108", name: "杨浦区", lng: 121.5260, lat: 31.2595 },
    { code: "3109", name: "闵行区", lng: 121.3816, lat: 31.1133 },
    { code: "3110", name: "宝山区", lng: 121.4891, lat: 31.4045 },
    { code: "3112", name: "嘉定区", lng: 121.2655, lat: 31.3747 },
  ],
  "32": [
    { code: "3201", name: "南京市", lng: 118.767413, lat: 32.041544 },
    { code: "3202", name: "无锡市", lng: 120.301663, lat: 31.574729 },
    { code: "3203", name: "徐州市", lng: 117.184811, lat: 34.261792 },
    { code: "3204", name: "常州市", lng: 119.946973, lat: 31.772752 },
    { code: "3205", name: "苏州市", lng: 120.619585, lat: 31.299379 },
    { code: "3206", name: "南通市", lng: 120.864608, lat: 32.016212 },
    { code: "3207", name: "连云港市", lng: 119.178821, lat: 34.600018 },
    { code: "3208", name: "淮安市", lng: 119.021265, lat: 33.597506 },
    { code: "3209", name: "盐城市", lng: 120.139998, lat: 33.377631 },
    { code: "3210", name: "扬州市", lng: 119.421003, lat: 32.393159 },
    { code: "3211", name: "镇江市", lng: 119.452753, lat: 32.204402 },
    { code: "3212", name: "泰州市", lng: 119.915176, lat: 32.484882 },
    { code: "3213", name: "宿迁市", lng: 118.275162, lat: 33.963008 }
  ],
  "33": [
    { code: "3301", name: "杭州市", lng: 120.153576, lat: 30.287459 },
    { code: "3302", name: "宁波市", lng: 121.549792, lat: 29.868388 },
    { code: "3303", name: "温州市", lng: 120.672111, lat: 28.000575 },
    { code: "3304", name: "嘉兴市", lng: 120.750865, lat: 30.762653 },
    { code: "3305", name: "湖州市", lng: 120.102398, lat: 30.867198 },
    { code: "3306", name: "绍兴市", lng: 120.582112, lat: 29.997117 },
    { code: "3307", name: "金华市", lng: 119.649506, lat: 29.089524 },
    { code: "3308", name: "衢州市", lng: 118.87263, lat: 28.941708 },
    { code: "3309", name: "舟山市", lng: 122.106863, lat: 30.016028 },
  ],
  "34": [
    { code: "3401", name: "合肥市", lng: 117.283042, lat: 31.861191 },
    { code: "3402", name: "芜湖市", lng: 118.376451, lat: 31.326319 },
    { code: "3403", name: "蚌埠市", lng: 117.363228, lat: 32.939667 },
    { code: "3404", name: "淮南市", lng: 117.018329, lat: 32.647574 },
    { code: "3405", name: "马鞍山市", lng: 118.507906, lat: 31.689362 },
    { code: "3406", name: "淮北市", lng: 116.794664, lat: 33.971707 },
    { code: "3407", name: "铜陵市", lng: 117.816576, lat: 30.929935 },
    { code: "3408", name: "安庆市", lng: 117.043551, lat: 30.50883 },
    { code: "3410", name: "黄山市", lng: 118.317325, lat: 29.709239 },
    { code: "3411", name: "滁州市", lng: 118.316264, lat: 32.303627 },
    { code: "3412", name: "阜阳市", lng: 115.819729, lat: 32.896969 },
    { code: "3413", name: "宿州市", lng: 116.984084, lat: 33.633891 },
    { code: "3415", name: "六安市", lng: 116.507676, lat: 31.752889 },
    { code: "3416", name: "亳州市", lng: 115.782939, lat: 33.869338 },
    { code: "3417", name: "池州市", lng: 117.489157, lat: 30.656037 },
    { code: "3418", name: "宣城市", lng: 118.758816, lat: 30.940718 }
  ],
  "35": [
    { code: "3501", name: "福州市", lng: 119.306239, lat: 26.075302 },
    { code: "3502", name: "厦门市", lng: 118.11022, lat: 24.490474 },
    { code: "3503", name: "莆田市", lng: 119.007558, lat: 25.431011 },
    { code: "3504", name: "三明市", lng: 117.635001, lat: 26.265444 },
    { code: "3505", name: "泉州市", lng: 118.589421, lat: 24.908853 },
    { code: "3506", name: "漳州市", lng: 117.661801, lat: 24.510897 },
    { code: "3507", name: "南平市", lng: 118.178459, lat: 26.635627 },
    { code: "3508", name: "龙岩市", lng: 117.02978, lat: 25.091603 },
    { code: "3509", name: "宁德市", lng: 119.527082, lat: 26.65924 }
  ],
  "36": [
    { code: "3601", name: "南昌市", lng: 115.892151, lat: 28.676493 },
    { code: "3602", name: "景德镇市", lng: 117.214664, lat: 29.29256 },
    { code: "3603", name: "萍乡市", lng: 113.852186, lat: 27.622946 },
    { code: "3604", name: "九江市", lng: 115.992811, lat: 29.712034 },
    { code: "3605", name: "新余市", lng: 114.930835, lat: 27.810834 },
    { code: "3606", name: "鹰潭市", lng: 117.033838, lat: 28.238638 },
    { code: "3607", name: "赣州市", lng: 114.940278, lat: 25.85097 },
    { code: "3608", name: "吉安市", lng: 114.986373, lat: 27.111699 },
    { code: "3609", name: "宜春市", lng: 114.391136, lat: 27.8043 },
    { code: "3610", name: "抚州市", lng: 116.358351, lat: 27.98385 },
    { code: "3611", name: "上饶市", lng: 117.971185, lat: 28.44442 }
  ],
  "37": [
    { code: "3701", name: "济南市", lng: 117.000923, lat: 36.675807 },
    { code: "3702", name: "青岛市", lng: 120.355173, lat: 36.082982 },
    { code: "3703", name: "淄博市", lng: 118.047648, lat: 36.814939 },
    { code: "3704", name: "枣庄市", lng: 117.557964, lat: 34.856424 },
    { code: "3705", name: "东营市", lng: 118.4963, lat: 37.461266 },
    { code: "3706", name: "烟台市", lng: 121.391382, lat: 37.539297 },
    { code: "3707", name: "潍坊市", lng: 119.107078, lat: 36.70925 },
    { code: "3708", name: "济宁市", lng: 116.587245, lat: 35.415393 },
    { code: "3709", name: "泰安市", lng: 117.129063, lat: 36.194968 },
    { code: "3710", name: "威海市", lng: 122.116394, lat: 37.509691 },
    { code: "3711", name: "日照市", lng: 119.461208, lat: 35.428588 },
    { code: "3713", name: "临沂市", lng: 118.326443, lat: 35.065282 },
    { code: "3714", name: "德州市", lng: 116.307428, lat: 37.453968 },
    { code: "3715", name: "聊城市", lng: 115.980367, lat: 36.456013 },
    { code: "3716", name: "滨州市", lng: 118.016974, lat: 37.383542 },
    { code: "3717", name: "菏泽市", lng: 115.469381, lat: 35.246531 }
  ],
  "41": [
    { code: "4101", name: "郑州市", lng: 113.665412, lat: 34.757975 },
    { code: "4102", name: "开封市", lng: 114.341447, lat: 34.797049 },
    { code: "4103", name: "洛阳市", lng: 112.434468, lat: 34.663041 },
    { code: "4104", name: "平顶山市", lng: 113.307718, lat: 33.735241 },
    { code: "4105", name: "安阳市", lng: 114.352482, lat: 36.103442 },
    { code: "4106", name: "鹤壁市", lng: 114.295444, lat: 35.748236 },
    { code: "4107", name: "新乡市", lng: 113.883991, lat: 35.302616 },
    { code: "4108", name: "焦作市", lng: 113.238266, lat: 35.23904 },
    { code: "4109", name: "濮阳市", lng: 115.041299, lat: 35.768234 },
    { code: "4110", name: "许昌市", lng: 113.826063, lat: 34.022956 },
    { code: "4111", name: "漯河市", lng: 114.026405, lat: 33.575855 },
    { code: "4112", name: "三门峡市", lng: 111.194099, lat: 34.777338 },
    { code: "4113", name: "南阳市", lng: 112.540918, lat: 32.999082 },
    { code: "4114", name: "商丘市", lng: 115.650497, lat: 34.437054 },
    { code: "4115", name: "信阳市", lng: 114.075031, lat: 32.123274 },
    { code: "4116", name: "周口市", lng: 114.649653, lat: 33.620357 },
    { code: "4117", name: "驻马店市", lng: 114.024736, lat: 32.980169 },
    { code: "4190", name: "济源市", lng: 112.590047, lat: 35.090378 }
  ],
  "42": [
    { code: "4201", name: "武汉市", lng: 114.298572, lat: 30.584355 },
    { code: "4202", name: "黄石市", lng: 115.077048, lat: 30.220074 },
    { code: "4203", name: "十堰市", lng: 110.787916, lat: 32.646907 },
    { code: "4205", name: "宜昌市", lng: 111.290843, lat: 30.702636 },
    { code: "4206", name: "襄阳市", lng: 112.144146, lat: 32.042426 },
    { code: "4207", name: "鄂州市", lng: 114.890593, lat: 30.396536 },
    { code: "4208", name: "荆门市", lng: 112.204251, lat: 31.03542 },
    { code: "4209", name: "孝感市", lng: 113.926655, lat: 30.926423 },
    { code: "4210", name: "荆州市", lng: 112.23813, lat: 30.326857 },
    { code: "4211", name: "黄冈市", lng: 114.879365, lat: 30.447711 },
    { code: "4212", name: "咸宁市", lng: 114.328963, lat: 29.832798 },
    { code: "4213", name: "随州市", lng: 113.37377, lat: 31.717497 },
    { code: "4228", name: "恩施土家族苗族自治州", lng: 109.48699, lat: 30.283114 },
    { code: "4290", name: "省直辖县级行政区划", lng: 114.298572, lat: 30.584355 }
  ],
  "43": [
    { code: "4301", name: "长沙市", lng: 112.982279, lat: 28.19409 },
    { code: "4302", name: "株洲市", lng: 113.151737, lat: 27.835806 },
    { code: "4303", name: "湘潭市", lng: 112.944052, lat: 27.82973 },
    { code: "4304", name: "衡阳市", lng: 112.607693, lat: 26.900358 },
    { code: "4305", name: "邵阳市", lng: 111.46923, lat: 27.237842 },
    { code: "4306", name: "岳阳市", lng: 113.132855, lat: 29.37029 },
    { code: "4307", name: "常德市", lng: 111.691347, lat: 29.040225 },
    { code: "4308", name: "张家界市", lng: 110.479921, lat: 29.127401 },
    { code: "4309", name: "益阳市", lng: 112.355042, lat: 28.570066 },
    { code: "4310", name: "郴州市", lng: 113.032067, lat: 25.793589 },
    { code: "4311", name: "永州市", lng: 111.608019, lat: 26.434516 },
    { code: "4312", name: "怀化市", lng: 109.97824, lat: 27.550082 },
    { code: "4313", name: "娄底市", lng: 112.008497, lat: 27.728136 },
    { code: "4331", name: "湘西土家族苗族自治州", lng: 109.739735, lat: 28.314296 }
  ],
  "44": [
    { code: "4401", name: "广州市", lng: 113.280637, lat: 23.125178 },
    { code: "4402", name: "韶关市", lng: 113.591544, lat: 24.801322 },
    { code: "4403", name: "深圳市", lng: 114.085947, lat: 22.547 },
    { code: "4404", name: "珠海市", lng: 113.553986, lat: 22.224979 },
    { code: "4405", name: "汕头市", lng: 116.708463, lat: 23.37102 },
    { code: "4406", name: "佛山市", lng: 113.122717, lat: 23.028762 },
    { code: "4407", name: "江门市", lng: 113.094942, lat: 22.590431 },
    { code: "4408", name: "湛江市", lng: 110.364977, lat: 21.274898 },
    { code: "4409", name: "茂名市", lng: 110.919229, lat: 21.659751 },
    { code: "4412", name: "肇庆市", lng: 112.472529, lat: 23.051546 },
    { code: "4413", name: "惠州市", lng: 114.412599, lat: 23.079404 },
    { code: "4414", name: "梅州市", lng: 116.117582, lat: 24.299112 },
    { code: "4415", name: "汕尾市", lng: 115.364238, lat: 22.774485 },
    { code: "4416", name: "河源市", lng: 114.697802, lat: 23.746266 },
    { code: "4417", name: "阳江市", lng: 111.975107, lat: 21.859222 },
    { code: "4418", name: "清远市", lng: 113.051227, lat: 23.685022 },
    { code: "4419", name: "东莞市", lng: 113.746262, lat: 23.046237 },
    { code: "4420", name: "中山市", lng: 113.382391, lat: 22.521113 },
    { code: "4451", name: "潮州市", lng: 116.632301, lat: 23.661701 },
    { code: "4452", name: "揭阳市", lng: 116.355733, lat: 23.543778 },
    { code: "4453", name: "云浮市", lng: 112.044439, lat: 22.929801 }
  ],
  "45": [
    { code: "4501", name: "南宁市", lng: 108.320004, lat: 22.82402 },
    { code: "4502", name: "柳州市", lng: 109.411703, lat: 24.314617 },
    { code: "4503", name: "桂林市", lng: 110.299121, lat: 25.274215 },
    { code: "4504", name: "梧州市", lng: 111.297604, lat: 23.474803 },
    { code: "4505", name: "北海市", lng: 109.119254, lat: 21.473343 },
    { code: "4506", name: "防城港市", lng: 108.345478, lat: 21.614631 },
    { code: "4507", name: "钦州市", lng: 108.624175, lat: 21.967127 },
    { code: "4508", name: "贵港市", lng: 109.602146, lat: 23.0936 },
    { code: "4509", name: "玉林市", lng: 110.154393, lat: 22.63136 },
    { code: "4510", name: "百色市", lng: 106.616285, lat: 23.897742 },
    { code: "4511", name: "贺州市", lng: 111.552056, lat: 24.414141 },
    { code: "4512", name: "河池市", lng: 108.062105, lat: 24.695899 },
    { code: "4513", name: "来宾市", lng: 109.229772, lat: 23.733766 },
    { code: "4514", name: "崇左市", lng: 107.353926, lat: 22.404108 }
  ],
  "46": [
    { code: "4601", name: "海口市", lng: 110.199891, lat: 20.044416 },
    { code: "4602", name: "三亚市", lng: 109.508268, lat: 18.247872 },
    { code: "4603", name: "三沙市", lng: 112.34882, lat: 16.831039 },
    { code: "4604", name: "儋州市", lng: 109.576782, lat: 19.517486 },
    { code: "4690", name: "省直辖县级行政区划", lng: 110.349228, lat: 20.017377 }
  ],
  "50": [
    { code: "5001", name: "重庆市", lng: 106.504962, lat: 29.533155 },
    { code: "5002", name: "县", lng: 106.504962, lat: 29.533155 }
  ],
  "51": [
    { code: "5101", name: "成都市", lng: 104.065735, lat: 30.659462 },
    { code: "5103", name: "自贡市", lng: 104.773447, lat: 29.352765 },
    { code: "5104", name: "攀枝花市", lng: 101.716007, lat: 26.580446 },
    { code: "5105", name: "泸州市", lng: 105.443348, lat: 28.889138 },
    { code: "5106", name: "德阳市", lng: 104.398651, lat: 31.127991 },
    { code: "5107", name: "绵阳市", lng: 104.741722, lat: 31.46402 },
    { code: "5108", name: "广元市", lng: 105.843357, lat: 32.435435 },
    { code: "5109", name: "遂宁市", lng: 105.571331, lat: 30.513311 },
    { code: "5110", name: "内江市", lng: 105.066138, lat: 29.58708 },
    { code: "5111", name: "乐山市", lng: 103.761263, lat: 29.582024 },
    { code: "5113", name: "南充市", lng: 106.082974, lat: 30.795281 },
    { code: "5114", name: "眉山市", lng: 103.831788, lat: 30.048318 },
    { code: "5115", name: "宜宾市", lng: 104.630825, lat: 28.760189 },
    { code: "5116", name: "广安市", lng: 106.633369, lat: 30.456398 },
    { code: "5117", name: "达州市", lng: 107.502262, lat: 31.209484 },
    { code: "5118", name: "雅安市", lng: 103.001033, lat: 29.987722 },
    { code: "5119", name: "巴中市", lng: 106.753669, lat: 31.858809 },
    { code: "5120", name: "资阳市", lng: 104.641917, lat: 30.122211 },
    { code: "5132", name: "阿坝藏族羌族自治州", lng: 102.221374, lat: 31.899792 },
    { code: "5133", name: "甘孜藏族自治州", lng: 101.963815, lat: 30.050663 },
    { code: "5134", name: "凉山彝族自治州", lng: 102.258746, lat: 27.886762 }
  ],
  "52": [
    { code: "5201", name: "贵阳市", lng: 106.713478, lat: 26.578343 },
    { code: "5202", name: "六盘水市", lng: 104.846743, lat: 26.584643 },
    { code: "5203", name: "遵义市", lng: 106.937265, lat: 27.706626 },
    { code: "5204", name: "安顺市", lng: 105.932188, lat: 26.245544 },
    { code: "5205", name: "毕节市", lng: 105.28501, lat: 27.301693 },
    { code: "5206", name: "铜仁市", lng: 109.191555, lat: 27.718346 },
    { code: "5223", name: "黔西南布依族苗族自治州", lng: 104.897971, lat: 25.08812 },
    { code: "5226", name: "黔东南苗族侗族自治州", lng: 107.977488, lat: 26.583352 },
    { code: "5227", name: "黔南布依族苗族自治州", lng: 107.517156, lat: 26.258219 }
  ],
  "53": [
    { code: "5301", name: "昆明市", lng: 102.712251, lat: 25.040609 },
    { code: "5303", name: "曲靖市", lng: 103.797851, lat: 25.501557 },
    { code: "5304", name: "玉溪市", lng: 102.543907, lat: 24.350461 },
    { code: "5305", name: "保山市", lng: 99.167133, lat: 25.111802 },
    { code: "5306", name: "昭通市", lng: 103.717216, lat: 27.336999 },
    { code: "5307", name: "丽江市", lng: 100.233026, lat: 26.872108 },
    { code: "5308", name: "普洱市", lng: 100.972344, lat: 22.777321 },
    { code: "5309", name: "临沧市", lng: 100.08697, lat: 23.886567 },
    { code: "5323", name: "楚雄彝族自治州", lng: 101.546046, lat: 25.041988 },
    { code: "5325", name: "红河哈尼族彝族自治州", lng: 103.384182, lat: 23.366775 },
    { code: "5326", name: "文山壮族苗族自治州", lng: 104.24401, lat: 23.36951 },
    { code: "5328", name: "西双版纳傣族自治州", lng: 100.797941, lat: 22.001724 },
    { code: "5329", name: "大理白族自治州", lng: 100.225668, lat: 25.589449 },
    { code: "5331", name: "德宏傣族景颇族自治州", lng: 98.578363, lat: 24.436694 },
    { code: "5333", name: "怒江傈僳族自治州", lng: 98.854304, lat: 25.850949 },
    { code: "5334", name: "迪庆藏族自治州", lng: 99.706463, lat: 27.826853 }
  ],
  "54": [
    { code: "5401", name: "拉萨市", lng: 91.132212, lat: 29.660361 },
    { code: "5402", name: "日喀则市", lng: 88.885148, lat: 29.267519 },
    { code: "5403", name: "昌都市", lng: 97.178452, lat: 31.136875 },
    { code: "5404", name: "林芝市", lng: 94.362348, lat: 29.654693 },
    { code: "5405", name: "山南市", lng: 91.766529, lat: 29.236023 },
    { code: "5406", name: "那曲市", lng: 92.060214, lat: 31.476004 },
    { code: "5425", name: "阿里地区", lng: 80.105498, lat: 32.503187 }
  ],
  "61": [
    { code: "6101", name: "西安市", lng: 108.948024, lat: 34.263161 },
    { code: "6102", name: "铜川市", lng: 108.979608, lat: 34.916582 },
    { code: "6103", name: "宝鸡市", lng: 107.237974, lat: 34.361979 },
    { code: "6104", name: "咸阳市", lng: 108.705117, lat: 34.333439 },
    { code: "6105", name: "渭南市", lng: 109.502882, lat: 34.499381 },
    { code: "6106", name: "延安市", lng: 109.489727, lat: 36.585455 },
    { code: "6107", name: "汉中市", lng: 107.028621, lat: 33.077668 },
    { code: "6108", name: "榆林市", lng: 109.734589, lat: 38.285391 },
    { code: "6109", name: "安康市", lng: 109.029273, lat: 32.6903 },
    { code: "6110", name: "商洛市", lng: 109.939776, lat: 33.868319 }
  ],
  "62": [
    { code: "6201", name: "兰州市", lng: 103.823557, lat: 36.058039 },
    { code: "6202", name: "嘉峪关市", lng: 98.277304, lat: 39.786529 },
    { code: "6203", name: "金昌市", lng: 102.187888, lat: 38.514238 },
    { code: "6204", name: "白银市", lng: 104.173606, lat: 36.54568 },
    { code: "6205", name: "天水市", lng: 105.724998, lat: 34.578529 },
    { code: "6206", name: "武威市", lng: 102.634697, lat: 37.929996 },
    { code: "6207", name: "张掖市", lng: 100.455472, lat: 38.932897 },
    { code: "6208", name: "平凉市", lng: 106.684691, lat: 35.54279 },
    { code: "6209", name: "酒泉市", lng: 98.510795, lat: 39.744023 },
    { code: "6210", name: "庆阳市", lng: 107.638372, lat: 35.734218 },
    { code: "6211", name: "定西市", lng: 104.626294, lat: 35.579578 },
    { code: "6212", name: "陇南市", lng: 104.929379, lat: 33.388598 },
    { code: "6229", name: "临夏回族自治州", lng: 103.212006, lat: 35.599446 },
    { code: "6230", name: "甘南藏族自治州", lng: 102.911008, lat: 34.986354 }
  ],
  "63": [
    { code: "6301", name: "西宁市", lng: 101.778916, lat: 36.623178 },
    { code: "6302", name: "海东市", lng: 102.103372, lat: 36.502916 },
    { code: "6322", name: "海北藏族自治州", lng: 100.901059, lat: 36.959435 },
    { code: "6323", name: "黄南藏族自治州", lng: 102.019988, lat: 35.517744 },
    { code: "6325", name: "海南藏族自治州", lng: 100.619542, lat: 36.280353 },
    { code: "6326", name: "果洛藏族自治州", lng: 100.242143, lat: 34.4736 },
    { code: "6327", name: "玉树藏族自治州", lng: 97.008522, lat: 33.004049 },
    { code: "6328", name: "海西蒙古族藏族自治州", lng: 97.370785, lat: 37.374663 }
  ],
  "64": [
    { code: "6401", name: "银川市", lng: 106.278179, lat: 38.46637 },
    { code: "6402", name: "石嘴山市", lng: 106.376173, lat: 39.01333 },
    { code: "6403", name: "吴忠市", lng: 106.199409, lat: 37.986165 },
    { code: "6404", name: "固原市", lng: 106.285241, lat: 36.004561 },
    { code: "6405", name: "中卫市", lng: 105.189568, lat: 37.514951 }
  ],
  "65": [
    { code: "6501", name: "乌鲁木齐市", lng: 87.617733, lat: 43.792818 },
    { code: "6502", name: "克拉玛依市", lng: 84.873946, lat: 45.595886 },
    { code: "6504", name: "吐鲁番市", lng: 89.184078, lat: 42.947613 },
    { code: "6505", name: "哈密市", lng: 93.51316, lat: 42.833248 },
    { code: "6523", name: "昌吉回族自治州", lng: 87.304012, lat: 44.014577 },
    { code: "6527", name: "博尔塔拉蒙古自治州", lng: 82.074778, lat: 44.903258 },
    { code: "6528", name: "巴音郭楞蒙古自治州", lng: 86.150969, lat: 41.768552 },
    { code: "6529", name: "阿克苏地区", lng: 80.265068, lat: 41.170712 },
    { code: "6530", name: "克孜勒苏柯尔克孜自治州", lng: 76.172825, lat: 39.713431 },
    { code: "6531", name: "喀什地区", lng: 75.989138, lat: 39.467664 },
    { code: "6532", name: "和田地区", lng: 79.92533, lat: 37.110687 },
    { code: "6540", name: "伊犁哈萨克自治州", lng: 81.317946, lat: 43.92186 },
    { code: "6542", name: "塔城地区", lng: 82.985732, lat: 46.746301 },
    { code: "6543", name: "阿勒泰地区", lng: 88.13963, lat: 47.848393 }
  ],
  "71": [
    { code: "7101", name: "台北市", lng: 121.5313, lat: 25.0375 },
    { code: "7102", name: "新北市", lng: 121.4656, lat: 25.0126 },
    { code: "7103", name: "桃园市", lng: 121.3008, lat: 24.9936 },
    { code: "7104", name: "台中市", lng: 120.6842, lat: 24.1488 },
    { code: "7105", name: "台南市", lng: 120.2141, lat: 22.9908 },
    { code: "7106", name: "高雄市", lng: 120.3132, lat: 22.6208 },
    { code: "7107", name: "基隆市", lng: 121.7419, lat: 25.1305 },
    { code: "7108", name: "新竹市", lng: 120.9684, lat: 24.8066 },
    { code: "7109", name: "嘉义市", lng: 120.4525, lat: 23.4804 },
    { code: "7110", name: "新竹县", lng: 121.0132, lat: 24.8389 },
    { code: "7111", name: "苗栗县", lng: 120.8234, lat: 24.5655 },
    { code: "7112", name: "彰化县", lng: 120.5161, lat: 24.0815 },
    { code: "7113", name: "南投县", lng: 120.9738, lat: 23.8313 },
    { code: "7114", name: "云林县", lng: 120.5245, lat: 23.7092 },
    { code: "7115", name: "嘉义县", lng: 120.3897, lat: 23.4518 },
    { code: "7116", name: "屏东县", lng: 120.4879, lat: 22.6827 },
    { code: "7117", name: "宜兰县", lng: 121.7542, lat: 24.7514 },
    { code: "7118", name: "花莲县", lng: 121.6033, lat: 23.9871 },
    { code: "7119", name: "台东县", lng: 121.1460, lat: 22.7562 },
    { code: "7120", name: "澎湖县", lng: 119.5793, lat: 23.5686 },
    { code: "7121", name: "金门县", lng: 118.3174, lat: 24.4367 },
    { code: "7122", name: "连江县", lng: 119.5397, lat: 26.1974 }
  ],
  "81": [
    { code: "8101", name: "中西区", lng: 114.1543, lat: 22.2837 },
    { code: "8102", name: "湾仔区", lng: 114.1829, lat: 22.2773 },
    { code: "8103", name: "东区", lng: 114.2260, lat: 22.2795 },
    { code: "8104", name: "南区", lng: 114.1600, lat: 22.2463 },
    { code: "8105", name: "油尖旺区", lng: 114.1733, lat: 22.3117 },
    { code: "8106", name: "深水埗区", lng: 114.1632, lat: 22.3309 },
    { code: "8107", name: "九龙城区", lng: 114.1928, lat: 22.3267 },
    { code: "8108", name: "黄大仙区", lng: 114.1959, lat: 22.3414 },
    { code: "8109", name: "观塘区", lng: 114.2314, lat: 22.3095 },
    { code: "8110", name: "荃湾区", lng: 114.1220, lat: 22.3708 },
    { code: "8111", name: "屯门区", lng: 113.9773, lat: 22.3910 },
    { code: "8112", name: "元朗区", lng: 114.0392, lat: 22.4422 },
    { code: "8113", name: "北区", lng: 114.1487, lat: 22.4941 },
    { code: "8114", name: "大埔区", lng: 114.1719, lat: 22.4501 },
    { code: "8115", name: "西贡区", lng: 114.2745, lat: 22.3809 },
    { code: "8116", name: "沙田区", lng: 114.1953, lat: 22.3791 },
    { code: "8117", name: "葵青区", lng: 114.1393, lat: 22.3569 },
    { code: "8118", name: "离岛区", lng: 113.9456, lat: 22.2815 }
  ],
  "82": [
    { code: "8201", name: "花地玛堂区", lng: 113.5528, lat: 22.2074 },
    { code: "8202", name: "圣安多尼堂区", lng: 113.5485, lat: 22.1238 },
    { code: "8203", name: "大堂区", lng: 113.5513, lat: 22.1899 },
    { code: "8204", name: "望德堂区", lng: 113.5501, lat: 22.1936 },
    { code: "8205", name: "风顺堂区", lng: 113.5419, lat: 22.1874 },
    { code: "8206", name: "嘉模堂区", lng: 113.5587, lat: 22.1560 },
    { code: "8207", name: "圣方济各堂区", lng: 113.5597, lat: 22.1235 },
    { code: "8208", name: "路凼填海区", lng: 113.5695, lat: 22.1367 }
  ]
}