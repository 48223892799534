<template>
  <div class="form-container" :style="backgroundStyle">
    <form class="form-items" @submit.prevent="submitForm">
      <div class="form-group">
        <label for="nickname">昵称：</label>
        <input type="text" id="nickname" maxlength="10" @input="handleInput" v-model.trim="formData.nickname" required
          placeholder="不需要真实姓名">
        <p v-if="containsSensitiveWord" style="color: red;">
          输入内容包含敏感词,请修改
        </p>
      </div>
      <div class="gender-switch">
        <span>男生</span>
        <label class="switch">
          <input type="checkbox" v-model="formData.gender">
          <span class="slider round"></span>
        </label>
        <span>女生</span>
      </div>
      <div class="form-group">
        <label>出生日期/时间：</label>
        <div class="date_input">
          <input id="timepicker" type="text" data-toid-date="date1" data-toid-hour="hour1" name="input_date" data-hour=""
            placeholder="请选择您的出生时间" readonly="readonly" />
          <input type="hidden" id="date1" name="date">
          <input type="hidden" id="hour1" name="hour">
        </div>
      </div>
      <div class="form-group location" style="line-height: 3vh;">
        <label>出生地：</label>
        <select v-model="formData.province" @change="onProvinceChange" required style="width:45vw;max-width:200px;"
          :style="selectstyle">
          <option value="">请选择省份</option>
          <option v-for="province in provinces" :key="province.code" :value="province.code">
            {{ province.name }}
          </option>
        </select>
        <select v-model="formData.city" @change="onCityChange" required style="float: right;width:45vw;max-width:200px;"
          :style="selectstyle">
          <option value="">请选择城市</option>
          <option v-for="city in cities" :key="city.code" :value="city.code">
            {{ city.name }}
          </option>
        </select>
      </div>
      <div class="map-container">
        <MapComponent ref="mapComponent" />
      </div>
      <button type="submit" :style="confirmstyle">提交</button>
    </form>
  </div>
</template>

<script>
import MapComponent from '../components/MapComponent.vue';
import { provinces, cities } from '../data/contries';
import { Toast } from 'vant';
import sensitiveWords from '../utils/sensitiveWords';

export default {
  components: {
    MapComponent
  },

  data() {
    return {
      formData: {
        nickname: '',
        birthDate: '',
        birthTime: '',
        isLunar: false,
        gender: false,
        province: '',
        city: ''
      },
      provinces,
      cities: [],
      showMap: false,
      mapCenter: { lng: 0, lat: 0 },
      backgroundStyle: {},
      confirmstyle: {},
      selectstyle: {},
      containsSensitiveWord: false,
    };
  },
  methods: {
    handleInput(event) {
      const newValue = event.target.value;
      const filteredValue = this.filterSensitiveWords(newValue);
      this.formData.nickname = filteredValue;
      this.containsSensitiveWord = newValue !== filteredValue;
    },

    filterSensitiveWords(text) {
      let filteredText = text;
      sensitiveWords.forEach(word => {
        const regex = new RegExp(word, 'gi');
        filteredText = filteredText.replace(regex, '*'.repeat(word.length));
      });
      return filteredText;
    },

    initDatepicker() {
      if (window.ruiDatepicker) {
        new window.ruiDatepicker().init('#timepicker')
      } else {
        // 如果脚本还没加载完，等待一段时间后重试
        setTimeout(() => this.initDatepicker(), 100);
      }
    },


    setVueDay(v) {
      console.log(v)
      let m = v.split("-")[1]
      let d = v.split("-")[2]
      if (parseInt(m) < 10 && parseInt(d) < 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + "0" + m + "-" + "0" + d;
      } else if (parseInt(m) > 10 && parseInt(d) < 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + m + "-" + "0" + d;
      } else if (parseInt(m) < 10 && parseInt(d) > 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + "0" + m + "-" + d;
      } else {
        this.formData.birthDate = v
      }
    },

    setVueTime(v) {
      console.log(v)
      if (v === -1) {
        this.formData.birthTime = "05:00";
      } else {
        this.formData.birthTime = v + ":00";
      }
    },

    onProvinceChange() {
      this.cities = cities[this.formData.province] || [];
      this.formData.city = '';
      this.showMap = false;
    },
    onCityChange() {
      if (this.formData.birthDate === '') {
        Toast('请先输入出生时间');
        this.formData.city = '';
        this.formData.province = '';
        return
      }
      this.showMap = true;
      const selectedCity = this.cities.find(city => city.code === this.formData.city);
      if (selectedCity) {
        this.$refs.mapComponent.addMarker(selectedCity.lng, selectedCity.lat, this.formData.birthDate + '日', selectedCity.name)
      }
    },
    updateBackground() {
      const date = new Date(this.formData.birthDate);
      const month = date.getMonth() + 1;
      const hour = parseInt(this.formData.birthTime);
      let season = '';
      if (month >= 3 && month <= 5) season = 'spring';
      else if (month >= 6 && month <= 8) season = 'summer';
      else if (month >= 9 && month <= 11) season = 'autum';
      else season = 'winter';
      let timeOfDay = '';
      if (hour >= 5 && hour <= 8) timeOfDay = 'brightness(1.0)';
      else if (hour > 8 && hour <= 10) timeOfDay = 'brightness(1.1)';
      else if (hour > 10 && hour <= 15) timeOfDay = 'brightness(1.2)';
      else if (hour > 15 && hour <= 19) timeOfDay = 'brightness(1.0)';
      else if (hour > 19 && hour < 24) timeOfDay = 'brightness(0.8)';
      else if (hour >= 0 && hour <= 5) timeOfDay = 'brightness(0.8)';
      else if (hour > 5 && hour <= 8) timeOfDay = 'brightness(0.9)';
      else timeOfDay = 'brightness(1.0)'
      let color = '#000'
      let fontweight = '600';
      this.backgroundStyle = {
        backgroundImage: `url(${require(`@/assets/${season}.png`)})`,
        filter: timeOfDay,
        color: color,
        fontWeight: fontweight
      };
      this.confirmstyle = {
        color: color,
        fontWeight: fontweight
      }
      this.selectstyle = {
        color: color,
        fontWeight: fontweight
      }

    },

    submitForm() {
      if (this.formData.birthDate !== "") {
        const today = new Date()
        const birth = new Date(this.formData.birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
          age--;
        }
        if (age < 18) {
          Toast('卷球的世界需要在18岁后进入,现在就请好好学习,加油!')
          return
        }
      }

      if (this.formData.birthDate === "") {
        Toast('请输入出生时间');
      } else {
        let datas = {
          nickName: this.formData.nickname,
          tagUndZeit: this.formData.birthDate + " " + this.formData.birthTime,
          gender: this.formData.gender === true ? 0 : 1
        }
        console.log(datas)
        // 处理表单提交
        this.$router.push({
          name: 'history',
          params: {
            userInfo: JSON.stringify(datas)
          }
        })
      }
    }
  },
  watch: {
    'formData.birthDate'() {
      this.updateBackground();
    },
    'formData.birthTime'() {
      this.updateBackground();
    }
  },
  mounted() {
    this.initDatepicker();
    this.$root.setVueDay = this.setVueDay;
    this.$root.setVueTime = this.setVueTime;
  }
};
</script>

<style scoped>
.form-container {
  margin: 0 auto;
  height: 90vh;
  width: 100vw; 
  background-size: cover;
  background-position: center;
  transition: all0.3s ease;
  background-color: #d9ceb2;
  box-sizing: border-box;
  padding: 5vh 4vw;
  background: linear-gradient(45deg,
      #e3e30a,
      #e5d30b,
      #d42a08
      );
  background-size: 100% 100%;
  background-attachment: fixed;
  max-width: 670px;
  min-height: 695px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
input[type="time"],
select {
  width: 94.5%;
  padding: 8px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.date-time-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* added flex-wrap for mobile */
}

.calendar-switch {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.gender-switch {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #8a7a63;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.map-container {
  margin-top: 10px;
  width: 95%;
  height: 250px;
  margin: 0 auto;
  max-width: 600px;
}

.map {
  width: 100%;
  height: 100%;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #f1ebe4;
  color: #000000;
  border: #f1ebe4 1px solid;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2vh;
  box-sizing: border-box;

}

button:hover {
  background-color: #3e311e;
}

/* Tablet and desktop styles */

@media (min-width:768px) {
  .form-container {
    width: 80vw;
    height: 70vh;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .date-time-group {
    gap: 20px;
  }

  .calendar-switch {
    margin-top: 10px;
  }

  .gender-switch {
    margin-bottom: 10px;
  }

}

/* Large desktop styles */

@media (min-width:1200px) {
  .form-container {
    width: 60vw;
    height: 50vh;
  }

  .form-group {
    margin-bottom: 30px;
  }

  .date-time-group {
    gap: 30px;
  }

  .calendar-switch {
    margin-top: 15px;
  }

  .gender-switch {
    margin-bottom: 15px;
  }

}
</style>
