<template>
    <div class="main">
        <div class="musk" :class="{ 'vanish': vanish }">
            <div class="sheet" :class="{ 'show': show }">
                <div class="slogen"></div>
                <button class="logo" @click="triggerAnimation">
                </button>
            </div>
        </div>

        <div class="container">
            <div class="left" :class="{ 'animateLeft': isAnimating }">
            </div>
            <div class="right" :class="{ 'animateRight': isAnimating }">
            </div>
            <div class="bottom" :class="{ 'jump': jump }">

            </div>
        </div>
    </div>

</template>

<script>
export default {
    beforeCreate() {
        if (window.localStorage.getItem("ssm-info")) {
            this.$router.push({ name: 'timeLine', params: { userId: window.localStorage.getItem("ssm-info") } })
        }
    },
    data() {
        return {
            isAnimating: false,
            show: false,
            vanish: false,
            jump: false
        }
    },
    methods: {
        triggerAnimation() {
            this.isAnimating = true
            this.show = true
            this.vanish = true
            this.jump = true
            setTimeout(() => {
                this.$router.push("/info")
            }, 1000);
        }
    },

}
</script>

<style>
.main {
    position: relative;
    height: 90vh;
    width: 100%;
}

.show {
    display: none;
}


.container {
    display: flex;
    width: 390px;
    height: 293px;
    position: absolute;
    top: 293px;
    flex-wrap: wrap;
}

.slogen {
    width: 270px;
    height: 128px;
    background-image: url('../assets/slogen.png');
    position: absolute;
    top: 6vh;
    background-repeat: no-repeat;
}

.left {
    width: 195px;
    height: 293px;
    background-image: url('../assets/wald.png');
}

.animateLeft {
    animation: openLeft 1s linear forwards;
}

.animateRight {
    animation: openRight 1s linear forwards;
}

.right {
    width: 195px;
    height: 293px;
    background-image: url('../assets/wald.png');
    background-position: 195px, 0;
}

.bottom {
    width: 150px;
    height: 150px;
    background-image: url("../assets/soxu.png");
    background-repeat: no-repeat;
    top: 25vh;
    left: 20vh;
    position: absolute;
}

.logo>a {
    display: block;
    width: 2vw;
    height: 2hv;
}

.musk {
    width: 100%;
    height: 90vh;
    background-image: url("../assets/ruck.png");
    opacity: 1;
    align-items: center;
    transform-style: preserve-3d;
    perspective: 250px;
    box-sizing: border-box;
    padding: 20px;
}

.sheet {
    box-sizing: border-box;
    width: 75vw;
    height: 35vh;
    border-radius: 10px;
    background-color: bisque;
    opacity: 0.7;
    box-sizing: border-box;
    margin: 0 auto;
    font-family: cursive;
    font-weight: 600;
    font-size: 5vw;
    position: relative;
}

.sheet>p {
    text-indent: 9vw;
    line-height: 3vh;
}

.jump {
    animation: jump 1s linear forwards;
}

.vanish {
    animation: vanish 1s linear forwards;
}

.logo {
    background-image: url("../assets/goodball.png");
    background-size: cover;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 27vh;
    left: 55vw;
    animation: spining 3s linear infinite;
    background-color: transparent;
    border: none;
}

@keyframes spining {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes openLeft {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

@keyframes openRight {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(-90deg);
    }
}

@keyframes vanish {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

@keyframes jump {
    0% {
        top: 35vh
    }

    20% {
        top: 40vh;
        background-image: url("../assets/soxu2.png");
    }

    100% {
        top: -70vh;
    }
}
</style>
