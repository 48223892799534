<template>
    <div class="exam-paper">
        <section class="true-false">
            <div v-for="(question, index) in trueFalseQuestions" :key="'tf' + index" class="question">
                <p>{{ index + 1 }}. {{ question.questionText }}</p>
                <div class="answer-options">
                    <button @click="selectTrueFalse(index, true)"
                        :class="{ selected: question.answerText === true }">✓</button>
                    <button @click="selectTrueFalse(index, false)"
                        :class="{ selected: question.answerText === false }">✗</button>
                </div>
            </div>
        </section>
        <section class="multiple-choice">
            <div v-for="(question, index) in multipleChoiceQuestions" :key="'mc' + index" class="question">
                <p>{{ index + 6 }}. {{ question.questionText }}</p>
                <div class="answer-options">
                    <button v-for="(option, optionIndex) in question.options" :key="optionIndex"
                        @click="selectMultipleChoice(index, optionIndex)"
                        :class="{ selected: question.answerText === optionIndex }">
                        {{ option }}
                    </button>
                </div>
            </div>
        </section>
        <button :disabled="isSubmitting" @click="submitExam" class="submit-btn">提交答案</button>
        <van-overlay :show="showOverlay" @click="closeOverlay">
            <div class="overlay-content">
                <div class="text-container">
                    <p>
                        为了给您提供更好的内容，
                        请耐心回答几个问题。
                    </p>
                </div>
                <van-button round type="primary" size="small" class="close-button" @click.stop="closeOverlay">
                    关闭
                </van-button>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import axios from '@/utils/requestAPI.js'
import { Overlay, Button } from 'vant';
import { Toast } from 'vant';
export default {
    components: {
        [Overlay.name]: Overlay,
        [Button.name]: Button,
    },

    props: ['userInfo'],
    created() {
        this.getFragen(this.userInfo)
    },
    data() {
        return {
            trueFalseQuestions: [

            ],
            multipleChoiceQuestions: [

            ],
            showOverlay: true,
            iden: '',
            isSubmitting: false
        };
    },
    methods: {
        closeOverlay() {
            this.showOverlay = false;
        },
        selectTrueFalse(index, value) {
            this.trueFalseQuestions[index].answerText = value;
        },
        selectMultipleChoice(index, optionIndex) {
            this.multipleChoiceQuestions[index].answerText = optionIndex;
        },
        submitExam() {
            window.localStorage.setItem("ssm-info", this.iden)
            this.isSubmitting = true
            Toast("谢谢耐心,请稍等")
            setTimeout(() => {
                this.$router.push({ name: 'timeLine', params: { userId: this.iden } })
            }, 1500);
        },

        async getFragen(userInfo) {
            console.log(userInfo)
            let ret = await axios.post("/ssme/addMal",
                userInfo
            )
            this.trueFalseQuestions = ret.data.tfQuestion
            this.multipleChoiceQuestions = ret.data.mulQuestion
            this.iden = ret.data.Inden
        }
    }
};
</script>

<style scoped>
.exam-paper {
    margin: 0 auto;
    height: 90vh;
    font-family: Arial, Helvetica, sans-serif;
    background: url('../assets/history.png');
    background-size: cover;
    font-weight: 600;
    color: #000;
    font-size: 13px;
    padding-top:5vh;
    box-sizing: border-box;
}

.question {
    background-color: #f8f9fa;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 3%;
    background: transparent;
}

.answer-options {
    display: flex;
    justify-content: space-around;

}

button {
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: transparent;
    border: #ecf1f1 dashed 2px;
}

button:hover {
    background-color: #bdc3c7;
}

button.selected {
    background-color: #8a7a63;
    color: white;
}

.submit-btn {
    margin: 0 auto;
    display: block;
    width: 98%;
    background-color: transparent;
    color: white;
    font-size: 18px;
    padding: 10px;
    border: 1px #8a7a63 solid;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 2vh;
}

.submit-btn:hover {
    background-color: #3e311e;
    color: white;
    transform: scale(1.05);
}

.question p{
 margin: 0.6vh;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.exam-paper {
    animation: fadeIn 0.5s ease-in-out;
}

.page {
    height: 100vh;
    width: 100vw;
}

.overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.text-container {
    background-color: #d9ceb2;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.text-container p {
    color: white;
    margin: 10px 0;
}

.close-button {
    z-index: 1;
}
</style>