<template>
    <div id="mapDiv" style="height: 250px;">
    </div>
</template>

<script>

export default {
    name: 'MapComponent',
    data() {
        return {
            map: '',
            oldlabel: ''
        };
    },
    mounted() {
        this.initMap();
    },

    update() {

    },
    methods: {
        initMap() {
            let map = new window.T.Map('mapDiv');
            map.centerAndZoom(new T.LngLat(116.40769, 39.89945), 7);
            map.disableScrollWheelZoom()
            map.disableDoubleClickZoom()
            this.map = map
        },
        flyTo(latlng) {
            this.map.panTo(latlng);
        },
        addMarker(lng, lat, content, loc) {
            if (this.oldlabel) {
                this.map.removeLayer(this.oldlabel)
            }
            let latlng = new window.T.LngLat(lng, lat);
            let label = new window.T.Label({
                text: '在' + content + '于' + loc + "<br>" + '出生的宝宝一枚',
                position: latlng,
            })
            this.oldlabel = label
            this.map.addOverLay(label)
            this.flyTo(latlng)
            const elements = document.querySelectorAll('.tdt-clickable');
            elements.forEach(element => {
                element.style.pointerEvents = 'none';
            });
        },
    }
};
</script>

<style scoped>
#mapDiv {
    pointer-events: none;
}

.tdt-label.tdt-clickable {
    pointer-events: none
}
</style>