<template>
    <div class="timeline-container">
        
        <div class="rainbow-tail" v-if="showRainBow" :class="{ 'rainbow-tail-show': showRainBow }"></div>
        <div class="timeline">
            <div v-for="(item, index) in visibleItems" :key="index" class="timeline-item"
                :class="{ 'left-content': index % 2 === 0, 'right-content': index % 2 !== 0 }">
                <div class="item-content">
                    <span class="time-lable">{{ item.stage }}</span><br>
                    {{ item.content }}<br>
                    <van-rate v-model="item.value" icon="like" void-icon="like-o" :count="3" />
                    <!-- <div class="heart" @click="toggleHeart(index)" :class="{ 'active': item.liked }">❤</div> -->
                </div>
            </div>
        </div>
        <div v-if="showEndDiv" class="end-div" @click="activateFunction">
            <img v-if="showImage" :src="endDivImage" alt="End Image">
        </div>
    </div>
</template>

<script>
import axios from '@/utils/requestAPI.js'
import image from '../assets/soxutime.png'
export default {
    props: ['userId'],
    name: 'timeLine',
    data() {
        return {
            timelineItems: [],
            visibleItems: [],
            showEndDiv: true,
            endDivImage: image,
            showImage: true,
            showRainBow: true
        }
    },
    mounted() {
        this.animateTimeline();
        setTimeout(() => {
            this.startAnimation = true;
            this.startBlinking();
        }, 4500);
        setTimeout(() => {
            this.showRainBow = false;
        }, 1000);
        this.fetchTimeLine()
    },
    methods: {
        async fetchTimeLine() {
            axios.get(`/ssme/zeitLinieNehmen/${this.userId}`).then(response => {
                this.timelineItems = response.data
            }).catch(error => {
                window.localStorage.removeItem("ssm-info")
                this.$router.push("/")
            })
        },
        animateTimeline() {
            let index = 0;
            const interval = setInterval(() => {
                if (index < this.timelineItems.length) {
                    this.visibleItems.push(this.timelineItems[index]);
                    index++;
                } else {
                    clearInterval(interval);
                    setTimeout(() => {
                        this.showEndDiv = true
                        this.showImage = true
                    }, 200);
                }
            }, 500);
        },
        toggleHeart(index) {
            this.visibleItems[index].liked = !this.visibleItems[index].liked;
        },
        activateFunction() {
            this.$router.push({ name: 'CalendarPage', params: { userId: this.userId } })
        },
        startBlinking() {
            setInterval(() => {
                this.showImage = !this.showImage;
            }, 600); // 每600毫秒切换一次可见性
        }
    }
}
</script>

<style scoped>
.timeline-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 5vh 5vw;
    height: 90vh;
    background-image: url('../assets/loon.png');
    box-sizing: border-box;
    background-position: right -25vw bottom -10vh;
    background-repeat: no-repeat;
}

.timeline {
    position: relative;
    padding: 2px 0;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 2%;
    background: linear-gradient(to bottom, #fcb045, #fd1d1d, #833ab4);
    top: 0;
    bottom: 0;
    left: 50%;
    animation: growLine 5s ease-out forwards;
}

@keyframes growLine {
    from {
        height: 0;
    }

    to {
        height: 100%;
    }
}

.timeline-item {
    padding: 3px, 3px;
    position: relative;
    background-color: transparent;
    width: 46%;
    height: 13vh;
    animation: fadeIn 1s ease-out forwards;
    opacity: 0;
    font-size: 2vh;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.left-content {
    left: 4%;
}

.right-content {
    left: 52%;
}

.item-content {
    padding: 5px 5px;
    background-color: white;
    border-radius: 6px;
    word-wrap: break-word;
    height: 13.5vh;
    position: relative;
    opacity: 0.8;
    font-weight: 600;
    font-size: 12px;
}

.time-lable {
    color: #833ab4;
    font-size: 14px;
}

.end-div {
    width: 20%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    animation: fadeIn 1s ease-out forwards;
    position: absolute;
    left: 40%;
    top: 2%;
    animation: slidedown 1.5s ease-out forwards;
    transition: box-shadow 0.3s ease;
    z-index: 9;
}

.end-div img {
    width: 100%;
    object-fit: cover;
    z-index: 9;
}

@keyframes slidedown {
    0% {}

    90% {
        top: 90vh
    }

    100% {
        top: 78vh
    }
}

.rainbow-tail {
    position: absolute;
    top: 40%;
    left: -47%;
    width: 195%;
    height: 20%;
    background: linear-gradient(to bottom,
            rgba(255, 0, 0, 0.5),
            rgba(255, 165, 0, 0.5),
            rgba(255, 255, 0, 0.5),
            rgba(0, 255, 0, 0.5),
            rgba(0, 0, 255, 0.5),
            rgba(75, 0, 130, 0.5),
            rgba(238, 130, 238, 0.5));
    opacity: 0;
    transform-origin: center;
    z-index: 2;

}

.rainbow-tail.rainbow-tail-show {
    animation: rain-bow ease-in-out forwards;
}

@keyframes rain-bow {
    0% {
        width: 0;
        opacity: 1;
        transform: rotate(0 deg);
    }

    20% {
        opacity: 1;
        transform: rotate(0 deg);
    }

    100% {
        /* width: 141vw; */
        opacity: 1;
        transform: rotate(90deg);
    }
}
</style>