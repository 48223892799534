<template>
  <header class="header">
    卷球个人日历
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.header {
  width: 100%; /* Full width */
  background-color: #f1ebe4; /* Background color */
  color: rgb(9, 26, 170); /* Text color */
  text-align: center; /* Center text */
  font-size: 2vh;
  height: 5vh;
  line-height: 5vh;
}

</style>