<template>
    <div class="calendar">
        <div class="calendar-header">
            <button @click="prevMonth">&lt;</button>
            <span>{{ currentYear }}年{{ currentMonth + 1 }}月</span>
            <button @click="nextMonth">&gt;</button>
        </div>

        <div class="calendar-body">
            <div class="weekdays">
                <div v-for="day in weekDays" :key="day" class="weekday">{{ day }}</div>
            </div>

            <div class="days">
                <div v-for="(day, index) in calendarDays" :key="index" class="day" :class="{
                    'empty': !day.date,
                    'today': day.isToday,
                    'current-month': day.currentMonth,
                    'clicked': clickedIndex === index
                }" @click="handleDayClick(day, index)">
                    <template v-if="day.date">
                        <div class="solar-day">{{ day.date.getDate() }}</div>
                        <div class="lunar-day">{{ day.lunar }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div class="drawer-container">
            <!-- 抽屉把手 -->
            <div class="drawer-handle" @click="closeDrawer">
                <div class="handle-line" v-show="showContent"></div>
            </div>
            <div class="drawer-content" :class="{ 'drawer-open': isOpen }">
                <div class="content-date" v-show="showContent">
                    <div class="calendar-modern-content">
                        <!-- 左侧日期区域 -->
                        <div class="calendar-modern-date-section">
                            <div class="calendar-modern-solar-date">2024年1月15日</div>
                            <div class="calendar-modern-lunar-date">癸卯年腊月初五</div>
                            <div class="calendar-modern-weekday">星期一</div>
                        </div>

                        <!-- 中间信息区域 -->
                        <div class="calendar-modern-info-section">
                            <div class="calendar-modern-festival">
                                <span class="calendar-modern-solar-term">小寒</span>
                                <span class="calendar-modern-festival-name">元旦</span>
                            </div>
                            <div class="calendar-modern-weather-box">
                                <div class="grid-item">城市</div>
                                <div class="grid-item">温度(℃)</div>
                                <div class="grid-item">天气</div>
                                <div class="grid-item">锡林郭勒</div>
                                <div class="grid-item">14-20</div>
                                <div class="grid-item">雾霾</div>
                            </div>
                            <div class="calendar-modern-birthday-countdown">
                                距离生日还有365天
                            </div>
                        </div>
                    </div>

                    <!-- 详情按钮 -->
                    <button class="calendar-modern-detail-btn">
                        查看详细
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Lunar from 'lunar-javascript'

export default {
    name: 'CalendarMonth',
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            weekDays: ['日', '一', '二', '三', '四', '五', '六'],
            clickedIndex: null,
            today: this.formatDate(new Date()),
            isOpen: false,
            showContent: false
        }
    },
    computed: {
        calendarDays() {
            const days = []
            const firstDay = new Date(this.currentYear, this.currentMonth, 1)
            const lastDay = new Date(this.currentYear, this.currentMonth + 1, 0)
            // 上个月的最后几天
            const firstDayWeekDay = firstDay.getDay()
            const prevMonthLastDay = new Date(this.currentYear, this.currentMonth, 0)
            for (let i = firstDayWeekDay - 1; i >= 0; i--) {
                const date = new Date(this.currentYear, this.currentMonth - 1, prevMonthLastDay.getDate() - i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: false,
                    isToday: this.isToday(date)
                })
            }
            // 当前月的天数
            for (let i = 1; i <= lastDay.getDate(); i++) {
                const date = new Date(this.currentYear, this.currentMonth, i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: true,
                    isToday: this.isToday(date)
                })
            }
            // 下个月的开始几天
            const remainingDays = 42 - days.length // 保持6行
            for (let i = 1; i <= remainingDays; i++) {
                const date = new Date(this.currentYear, this.currentMonth + 1, i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: false,
                    isToday: this.isToday(date)
                })
            }
            return days
        }
    },
    methods: {
        toggleDrawer() {
            this.isOpen = true;
            setTimeout(() => {
                this.showContent = true
            }, 300);
        },
        closeDrawer() {
            this.isOpen = false
            this.showContent = false
        },
        handleDayClick(day, index) {
            if (!day.date) return;
            this.clickedIndex = index;
            setTimeout(() => {
                this.clickedIndex = null;
            }, 300);
            this.toggleDrawer()
            console.log('点击的日期：', day.date);
        },
        prevMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11
                this.currentYear--
            } else {
                this.currentMonth--
            }
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0
                this.currentYear++
            } else {
                this.currentMonth++
            }
        },
        isToday(date) {
            const today = new Date()
            return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
        },
        getLunarDate(date) {
            const lunar = Lunar.Lunar.fromDate(date)
            const lunarDay = lunar.getDayInChinese()

            // 获取节气
            const term = lunar.getJieQi()
            if (term) {
                return term
            }

            const holiday = lunar.getFestivals()
            if (holiday.length !== 0) {
                return holiday[0];
            }

            // 获取农历日期
            if (lunar.getDay() === 1) {
                return lunar.getMonthInChinese() + '月'
            }

            return lunarDay
        },
        formatDate(date, format = 'yyyy-mm-dd') {
            const map = {
                yyyy: date.getFullYear(),
                mm: String(date.getMonth() + 1).padStart(2, '0'),
                dd: String(date.getDate()).padStart(2, '0')
            };
            return format.replace(/yyyy|mm|dd/gi, matched => map[matched]);
        }
    }
}
</script>

<style scoped>
.calendar {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    min-height: 90vh;
    background:
        /* 云朵效果 */
        radial-gradient(circle at 20% 30%,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 0) 50%),
        radial-gradient(circle at 70% 20%,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 0) 50%),
        /* 天空和麦田 */
        linear-gradient(to bottom,
            #47a6ff 0%,
            #88edea 60%,
            #eeee53 80%,
            #da8020 95%,
            #d97b09 100%);
    position: relative;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: transparent;

}

.calendar-header button {
    padding: 5px 10px;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    padding: 10px 0;
    background: transparent;
    margin-top: 6vh;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
}

.day {
    aspect-ratio: 1;
    padding: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    background: transparent;
}

.day.clicked {
    transform: scale(1.1);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.05);
}

/* 悬停效果 */
.day:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* 点击时的涟漪效果 */
@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.day::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    pointer-events: none;
}

.day.clicked::after {
    animation: ripple 0.6s ease-out;
}

.today {
    background-color: #19a0de;
}

.solar-day {
    font-size: 16px;
    font-weight: bold;
}

.lunar-day {
    font-size: 12px;
    color: #666;
}

.current-month {
    color: #333;
}

.day:not(.current-month) {
    color: #ccc;
}

.drawer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.drawer-handle {
    height: 20px;
    background-color: #f5f5f5;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.handle-line {
    width: 40px;
    height: 4px;
    background-color: #999;
    border-radius: 2px;
}

.drawer-content {
    background-color: #999;
    height: 0vh;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-sizing: border-box;
}

.drawer-content.drawer-open {
    height: 30vh;
}

.content-date {
    width: 100vw;
    height: 30vh;
    box-sizing: border-box;
    padding: 1.5vh 2vw;
    background: linear-gradient(135deg, #fff5f5 0%, #fff8f0 100%);
    position: relative;
    font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

.calendar-modern-content {
    display: flex;
    height: 100%;
}

.calendar-modern-date-section {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3vw;
}

.calendar-modern-solar-date {
    font-size: 2.5vh;
    color: #333;
    font-weight: 600;
    margin-bottom: 1vh;
}

.calendar-modern-lunar-date {
    font-size: 2vh;
    color: #666;
    margin-bottom: 0.8vh;
}

.calendar-modern-weekday {
    font-size: 1.8vh;
    color: #888;
}

.calendar-modern-info-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1vh 2vw;
}

.calendar-modern-festival {
    display: flex;
    gap: 2vw;
    margin-bottom: 1vh;
}

.calendar-modern-solar-term,
.calendar-modern-festival-name {
    padding: 0.5vh 1.5vw;
    background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
    border-radius: 15px;
    color: white;
    font-size: 1.6vh;
}

.calendar-modern-weather-box {
    width: 44vw;
    height: 10vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin: 1vh 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 创建7个相等宽度的列 */
    grid-template-rows: repeat(2, 1fr);
    /* 创建2个相等高度的行 */
    gap: 0;
    font-size: 13px;
    background: transparent;
}

.calendar-modern-birthday-countdown {
    font-size: 1.6vh;
    color: #666;
}

.calendar-modern-detail-btn {
    position: absolute;
    bottom: 2vh;
    right: 2vw;
    padding: 1vh 2vw;
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 1.6vh;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 8px rgba(253, 160, 133, 0.3);
}

.calendar-modern-detail-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(253, 160, 133, 0.4);
}

.calendar-modern-detail-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(253, 160, 133, 0.3);
}


.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}
</style>