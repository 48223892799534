import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from './views/Welcome.vue'
import Info from './views/Info.vue'
import History from './views/History.vue'
import CalendarPage from './views/CalendarPage.vue'
import CalendarDay from './views/CalendarDay.vue'
import TimeLine from './views/TimeLine.vue'
import CalendarMonth from './views/CalendarMonth.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', component: Welcome },
  { path: '/info', component: Info },
  {
    path: '/timeLine',
    name: 'timeLine',
    component: TimeLine,
    props: true,
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    props: route => ({ userInfo: JSON.parse(route.params.userInfo) })
  },
  {
    path: '/CalendarPage',
    name: 'CalendarPage',
    component: CalendarPage,
    props: true,
  },
  {
    path: '/CalendarDay',
    name: 'CalendarDay',
    component: CalendarDay,
    props: true,
  },

  {
    path: '/CalendarMonth',
    name: 'CalendarMonth',
    component: CalendarMonth,
    props: true,
  },

]

const router = new VueRouter({
  routes
})

export default router
