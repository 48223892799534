<template>
    <div class="calendar-page">
        <div class="spiral-flying-image" :class="{ 'spiral-animate': isFlying }" @click="startFlying"></div>
        <div class="spiral-rainbow-trail" :class="{ 'spiral-animate': isFlying }"></div>
        <div class="calendar-header">
            <span class="year">{{ currentYear }}年</span>
            <span class="month">{{ currentMonth }}月</span>
            <span class="day">{{ currentDay }}日</span>
        </div>
        <div class="calendar-grid">
            <div v-for="(day, index) in monthDays" :key="index" :class="ifToday(day, index)" class="calendar-cell"
                @click="onCellClick(day, index)">
                <span class="emoji">{{ getEmoji(day) }}</span>
                <div :class="getWeekDay(day)" id="week_pic"></div>
                <div class="date-info">
                    <span class="solar-date">{{ day.solarDay }}</span>
                    <span class="weekday">{{ day.weekDay }}</span>
                </div><span class="lunar-date">{{ day.lunarMonth }}{{ day.lunarDay }}</span>
            </div>
        </div>
    </div>
</template>
<script>

import { Toast } from 'vant';
import axios from '@/utils/requestAPI.js'
export default {
    props: ['userId', 'backMonate'],
    name: 'CalendarPage',
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth() + 1,
            currentDay: new Date().getDate(),
            monthDays: [],
            isFlying: false
        };
    },
    mounted() {
        if (this.backMonate) {
            this.monthDays = this.backMonate
        } else {
            this.fetchMonthData();
        }
    },
    methods: {
        async fetchMonthData() {
            axios.get(`/ssme/nehmMonate/${this.userId}`).then(response => {
                this.monthDays = response.data
            }).catch(error => {
                window.localStorage.removeItem("ssm-info")
                this.$router.push("/")
            })
        },
        getEmoji(day) {
            const emojis = { normal: '😐', smile: '😊', laugh: '😄' };
            let arrayemo = [emojis.normal, emojis.smile, emojis.laugh]
            // return emojis[day.mood] || emojis.laugh;
            return arrayemo[Math.floor(Math.random() * 3)];
        },
        onCellClick(day, index) {
            // 添加3D翻转效果
            const cell = event.currentTarget;
            cell.style.transform = 'perspective(1000px) rotateY(30deg)';
            Toast('跳转到详情页...');
            // 延迟跳转到第二个页面
            setTimeout(() => {
                // 这里添加跳转逻辑
                this.$router.push({ name: 'CalendarDay', params: { monthData: this.monthDays, initialId: index } });
            }, 500);
        },
        getWeekDay(day) {
            if (day.weekDay === "星期日" || day.weekDay === "星期六") {
                return "weekends"
            } else {
                return "weekdays"
            }
        },
        ifToday(day) {
            let today = new Date()
            if(parseInt(today.getDate().toString())<10){
                today="0"+today.getDate().toString()
            }
            console.log()
            console.log(day.solarDay)
            if (day.solarDay === today) {
                return "today"
            }
        },
        startFlying() {
            if (this.isFlying) return;
            this.isFlying = true;
            setTimeout(() => {
                this.isFlying = false;
                window.localStorage.removeItem("ssm-info")
                Toast('信息清除了，可以录入新的啦');
                this.$router.push("/")
            }, 1500);
        }
    },
};
</script>

<style scoped>
.calendar-page {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    padding: 20px;
    height: 90vh;
    box-sizing: border-box;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2vh;
}

.calendar-cell {
    aspect-ratio: 1;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 215, 0, 0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: transform 0.3s ease;
    box-sizing: border-box;
    width: 21.5vw;
}

.today {
    background: rgba(255, 255, 255, 0.8);
}

.emoji {
    position: absolute;
    top: 0.5vh;
    left: 0.25vw;
    font-size: 5vw;
}

#week_pic {
    position: absolute;
    top: 0.5vh;
    left: 15.25vw;
    width: 5vw;
    height: 2.2vh;
}

.weekdays {
    background-image: url('@/assets/woche.png');
    background-repeat: no-repeat;
}

.weekends {
    background-image: url('@/assets/wochende.png');
    background-repeat: no-repeat;
}


.date-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.solar-date {
    font-size: 24px;
    font-weight: bold;
}

.weekday {
    font-size: 14px;
}

.lunar-date {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    opacity: 0.8;
}

.year {
    text-align: left;
}

.month {
    text-align: center;
}

.day {
    text-align: right;
}

.spiral-rainbow-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.spiral-flying-image {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-image: url('../assets/soxu2.png');
    background-size: cover;
    cursor: pointer;
    z-index: 10;
}

.spiral-flying-image.spiral-animate {
    animation: spiral-fly-animation 2s ease-in-out forwards;
}

.spiral-rainbow-trail {
    position: absolute;
    top: 45px;
    left: 45px;
    width: 0;
    height: 100px;
    background: linear-gradient(to bottom,
            rgba(255, 0, 0, 0.5),
            rgba(255, 165, 0, 0.5),
            rgba(255, 255, 0, 0.5),
            rgba(0, 255, 0, 0.5),
            rgba(0, 0, 255, 0.5),
            rgba(75, 0, 130, 0.5),
            rgba(238, 130, 238, 0.5));
    opacity: 0;
    transform-origin: left top;
    z-index: 5;
    border-radius: 50px;
}

.spiral-rainbow-trail.spiral-animate {
    animation: spiral-rainbow-trail-animation 2s ease-in-out forwards;
}

@keyframes spiral-fly-animation {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }

    25% {
        transform: translate(25vw, 25vh) rotate(90deg);
        height: 100px;
        width: 150px;
    }

    50% {
        transform: translate(50vw, 50vh) rotate(180deg);
        height: 150px;
        width: 200px;
    }

    75% {
        transform: translate(75vw, 75vh) rotate(270deg);
        height: 200px;
        width: 250px;
    }

    100% {
        transform: translate(calc(100vw - 70px), calc(100vh - 70px)) rotate(360deg);
        height: 250px;
        width: 300px;
    }
}

@keyframes spiral-rainbow-trail-animation {
    0% {
        width: 0;
        opacity: 0;
        transform: rotate(0deg) scaleY(0);
    }

    20% {
        opacity: 1;
        transform: rotate(0deg) scaleY(1);
    }

    100% {
        width: 141vw;
        opacity: 0;
        transform: rotate(45deg) scaleY(1);
    }
}
</style>
